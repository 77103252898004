import PropTypes from 'prop-types'
import React from 'react'
import bench from '../images/bench.png'
import family from '../images/family-photo2.jpg'
import screenshot from '../images/website.png'
import simply from '../images/simply-seattle.png'
import storyville from '../images/storyville.jpg'
import cookiedaughters from '../images/cookiedaughters_ss.png'
import sososweet from '../images/sososweet_ss.png'
import wheatstalk from '../images/wheatstalk_ss.png'
import ContactForm from './contact-form'

class Main extends React.Component {


  render() {
    let close = (
      <div
        role="button"
        tabIndex={0}
        aria-label="close window"
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
        onKeyDown={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={bench} alt="bench" />
          </span>
          <p>
            Hello and Welcome!
            <br/>
            I am a Full-Stack JavaScript developer with experience in many languages, libraries and coding practices. The list below is just a small sample.
          </p>
          <h4>NodeJS | React | SQL | Mongodb | Express | Agile </h4>
          <p>
            Please have a look around my page, if you like what you see head over to the "Contact" page and send me a message. 
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work</h2>

          
          {/* SoSoSweet by Ashley */}
            <h3 className="projects">
              <a href="https://www.sososweetbyashley.com/" target="_blank"  rel="noreferrer" className="hide-link">
                So So Sweet by Ashley
              </a>
            </h3>
            <h5>
              CSS | HTML | Zapier | Squarespace
            </h5>
            <span className="image main">
              <a href="https://www.sososweetbyashley.com/" target="_blank"  rel="noreferrer" className="hide-link">
                <img src={sososweet} alt="So So Sweet by Ashley" />
              </a>
            </span>
            <hr/>
          

          {/* Wheatstalk Boutique */}
            <h3 className="projects">
              <a href="https://www.wheatstalkboutique.com/" target="_blank"  rel="noreferrer" className="hide-link">
                Wheatstalk Boutique
              </a>
            </h3>
            <h5>
              React | Gatsby | Netlify CMS | Shopify | GraphQL | Netlify
            </h5>
            <span className="image main">
              <a href="https://www.wheatstalkboutique.com/" target="_blank"  rel="noreferrer" className="hide-link">
                <img src={wheatstalk} alt="Wheatstalk Boutique" />
              </a>
            </span>

          <hr/>


          {/* Cookie Daughters */}
            <h3 className="projects">
              <a href="https://www.cookiedaughters.com/" target="_blank"  rel="noreferrer" className="hide-link">
                Cookie Daughters
              </a>
            </h3>
            <h5>
              Shopify | Gatsby | Styled-Components | React | GraphQL | Netlify
            </h5>
            <span className="image main">
              <a href="https://www.cookiedaughters.com/" target="_blank"  rel="noreferrer" className="hide-link">
                <img src={cookiedaughters} alt="Cookie Daughters" />
              </a>
            </span>

          <hr/>

          {/* Personal Portfolio */}
            <h3 className="projects">Personal Portfolio</h3>
            <h5>
              React | SCSS | Netlify | HTML
            </h5>
            <span className="image main">
              <img src={screenshot} alt="portfolio-screenshot" />
            </span>

          <hr/>

          {/* Simply Seattle */}
            <h3 className="projects" style={{marginBottom: '0'}}>
              <a href="https://www.simplyseattle.com/" target="_blank"  rel="noreferrer" className="hide-link">
                Simply Seattle
              </a>
            </h3>
            <p style={{fontSize:'.8rem', marginBottom: '.6rem'}}>Footer Menu and Email Block</p>
            <h5>
              JavaScript | CSS | Shopify
            </h5>
            <span className="image main">
              <a href="https://www.simplyseattle.com/" target="_blank"  rel="noreferrer" className="hide-link">
                <img src={simply} alt="simplyseattle-screenshot" />
              </a>
            </span>

          <hr/>
          
          {/* Storyville Coffee Company */}
            <h3 className="projects" style={{marginBottom: '0'}}>
              <a href="https://storyville.com/" target="_blank" rel="noreferrer" className="hide-link">
                Storyville Coffee Company
              </a>
            </h3>
            <p style={{fontSize:'.8rem', marginBottom: '.6rem'}}>Proprietary Training Site</p>
            <h5>
              React | Node | Express | MongoDB | SCSS
            </h5>
            <span className="image main">
            <a href="https://storyville.com/" target="_blank" rel="noreferrer" className="hide-link">
              <img src={storyville} alt="storyville-screenshot" />
            </a>
            </span>

          {close} 
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img className="family-photo" src={family} alt="wife-and-i" />
          </span>
          <p>
          Hi There! I see you have made your way over to my "About" section, thank you so much for taking the time 
          to explore my page.
          <br/>
          <br/>
          In case you missed it, my name is Kyle Richey. I am a husband, father, and a lover of all things programming. 
          My wife and I have been married for 8+ years now and we have a wonderfully adventurous 3 year old son. They are 
          a huge inspiration to me and their constant encouragement has helped me to get to where I am today. 
          <br/>
          <br/> 
          I was born and raised in the great PNW and I can't imagine life anywhere else. Skiing, hiking, camping, swimming -  
          living here allows me to do it all! I was also raised in a programmer household, with both my parents having careers
           in the field. This career is the unavoidable outcome of being raised by them and in one of the great developer meccas 
           of the US. 
          <br/>
          <br/>
          If you are looking for projects I have done, hop on over to the "Work" page.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>

          <h4>Shoot me a message here.</h4>
          <ContactForm />
          
          <br/>
          <br/>
          <h4>I'm pretty social.</h4>

          <ul className="icons">
            <li>
              <a href="https://github.com/krichey15" className="icon fa-github">
                <span className="label">GitHub</span>
              </a>
            </li>

            <li>
              <a href="https://www.linkedin.com/in/kyle-richey/" className="icon fa-linkedin">
                <span className="label">LinkedIn</span>
              </a>
            </li>

            <li>
              <a href="https://www.facebook.com/kyle.richey1/" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>

          </ul>
          {close}
        </article>
      </div>
    )
  }
}


Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
