import React from 'react';

const ContactForm = () => {
  return (
    <div>
        <form name='contact' method='POST' encType='multipart/form-data' data-netlify='true'>
            <input type='hidden' name='contact-form' value='contact' />

            <label htmlFor='name'>Name: </label>
            <input type='text' id='name' name='name' required />

            <label htmlFor='company'>Company (if applicable): </label>
            <input type='text' id='company' name='company' />

            <label htmlFor='email'>Email: </label>
            <input type='email' id='email' name='email' required />

            <label htmlFor='message'>Message: </label>
            <textarea id='message' name='message' rows='4' required />

            <button className='button special' type='submit'>Send</button>
        </form>
    </div>
  )
}

export default ContactForm;